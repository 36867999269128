import React from "react"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import TripleColumnBlock from "../components/tripleColumnBlock"
import Blockquote from "../components/blockquote"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  RequestAQuoteButton,
} from "../components/buttons"


const GlassBacksplashApplications = () => {
  return (
    <PageLayout>
      <SEO
        title="Glass Backsplash | ArtVue Glass"
        ogDescription="Are you looking to transform your walls with the elegance of glass? Choose your favorite hue and customize a back painted glass backsplash to revamp your interior space with contemporary design and aesthetics. Contact today for a consultation!"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>
              Glass Backsplash
            </h1>
            <h2>
              Minimalistic, Modern, and Aesthetic – Glass Backsplash Can Transform Your Space for Good
            </h2>
            <h2>
              What Is a Glass Backsplash?
            </h2>
            <p>
              Trendy, contemporary, and truly unique, the glass backsplash is the newest rage in the interior design industry, and the trend does not seem to be slowing down any time soon.
            </p>
            <p>
              As the name suggests, the glass “backsplash” is a panel(s) installed on the wall behind areas or facilities that are susceptible to splashing. Therefore, it is mostly used in kitchens, behind sinks, and stoves.
            </p>
            <p>
              Paint the glass sheet in a color of your choice, and breathe life into your otherwise mundane kitchen walls, leaving an impression on anyone who walks in.
            </p>
            <h2>
              Kitchen Glass Backsplash
            </h2>
            <p>
              Are you tired of painting and repainting your kitchen walls due to the stubborn grease stains that simply won’t go? Is maintaining your kitchen’s hygiene becoming a difficult endeavor for you?
            </p>
            <p>
              With a back painted glass backsplash for walls in your kitchen, the task of kitchen cleaning becomes as easy as cake.
            </p>
            <p>
              Here’s why glass works better than most materials for a kitchen backsplash:
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        textPadded
        textLeft={
          <div>
            &#10003; Easy To Wipe and Clean
          </div>
        }
        textCenter={
          <div>
            &#10003; Numerous Sizes
          </div>
        }
        textRight={
          <div>
            &#10003; Sleek Finish
          </div>
        }
      />
      <TripleColumnBlock
        textPadded
        textLeft={
          <div>
            &#10003; Customizable Colors
          </div>
        }
        textCenter={
          <div>
            &#10003; Practical Outlook
          </div>
        }
        textRight={
          <div>
            &#10003; Durable
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <p>
              Have a tomato sauce stain on the wall? Has grease made its way past the stove and onto the wall behind? These are some of the hardest things to clean off a wall.
            </p>
            <p>
              Swipe, clean, and find your colored glass backsplash as good as new – it’s really that simple.
            </p>
            <h2>
              Tempered Glass Backsplash
            </h2>
            <p>
              When choosing glass for interiors, breakage always remains a concern at the back of the mind. Fortunately, this is where tempered glass shines.
            </p>
            <p>
              About 4 times stronger than regular glass, tempered glass is a thermal processed glass with added safety that does not break easily; and when it does, it shatters into very small, harmless pieces instead of big shards.
            </p>
            <p>
              Want to create an accent wall in your washroom with a colored glass backsplash and an added guarantee of safety? Wish to revamp your corridor interiors with a glass wall behind a mini, artificial waterfall?
            </p>
            <p>
              Choose a beautiful tempered glass backsplash for your home or office, and keep your breakage worries at bay. 
            </p>
            <h2>
              Laminated Glass Backsplash
            </h2>
            <p>
              Glass-wall cladding can seem dangerous, especially if you have children in the house. Even the idea of small pieces of otherwise harmless glass can seem risky.
            </p>
            <p>
              With a laminated glass backsplash, the safety of your glass is multiplied drastically. The glass has immense strength and is famed for its ability to not break into shards. This means, when hit with excessive force, while the glass backsplash can break, it will not crowd the floor with broken glass pieces.
            </p>
            <p>
              Have a child at home? A laminated glass backsplash will make the area aesthetic as well as baby-proof. 
            </p>
            <p>
              Pastels or neons, light or darks, incorporate creative visuals in laminated glass backsplash and make your interiors stand out.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        centered
        text={
          <div>
            <h2>Types Of Glass Backsplash</h2>
            <p>
              Have a tomato sauce stain on the wall? Has grease made its way past the stove and onto the wall behind? These are some of the hardest things to clean off a wall.
            </p>
            <p>
              But first, there is an important decision to make: The type of glass backsplash.
            </p>
            <p>
              From back painted to glass tiles and mirrored ones, the choices are many.
            </p>
            <p>
              Let’s discover! 
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3></h3>
            <p>
              In this backsplash, the glass is painted from the back with a solid, opaque color. As the paint bonds to the glass, it shines through the front, exuding a sleek finish and a mildly glossy look.
            </p>
            <p>
              The back painted glass backsplash has, therefore, become a popular choice for interior enthusiasts. It is easy to wipe, non-porous, does not trap bacteria, and reflects light splendidly to create a colorful, breathable environment. 
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>Antique Mirror Backsplash</h3>
            <p>
              For vintage enthusiasts, there is nothing better than a mirror that creates a vibe of the past for home or commercial interiors. With an antique mirror backsplash, this becomes easy. 
            </p>
            <p>
              Created with clear or low iron glass, the mirror can be simple, patterned, bordered, or decorated with a distinct monochrome, vintage look. 
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>Antique Mirror Tile Backsplash</h3>
            <p>
              Have you seen mirror tiles in Nordic-style rooms?
            </p>
            <p>
              Available in a multitude of shapes – hexagonal, beehive, bevel, rectangle, etc. – these tiles are the newest rage, and rightly so.
            </p>
            <p>
              Choose a mirror in the shape you desire, or have it customized to your preferences, and use it as a creative backsplash for kitchens, washrooms, kids’ rooms, art rooms, etc. 
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>Glass Tile Backsplash</h3>
            <p>
              Do you want to take your glass game a notch higher?
            </p>
            <p>
              With glass tile backsplashes, you can not only play with colors but also prints and designs to uplift the look of your backsplash. 
            </p>
            <p>
              Choose a tile with a simple design or turn to aesthetic mosaics  
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>Standard Mirror Backsplash</h3>
            <p>
              The addition of a mirror to a space makes it appear more spacious, airy, and modern. 
            </p>
            <p>
              With mirrors of the perfect shape and size, create an aesthetic illusion for all passersby, and garner compliments from everyone. 
            </p>
            <p>
              Choose between tinted, bevel, ornamented, facet, or several other types of mirrors and revamp your space today.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        centered
        text={
          <div>
            <h3>Colors That Transform Your Space into A Masterpiece</h3>
            <p>
          If you are someone who likes colors, prints, creativity, or all things crafty; a glass backsplash might just be the best addition to your place. 
            </p>
            <p>
              Choose tiles that resonate with your idea or personalize your look with your favorite color back painted with the backsplash. 
            </p>
            <p>
              Have your pick:
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>
              Solid Color Glass Backsplash
            </h4>
            <p>
              Use the GPT Color Matching system for the best opaque hues – pastels or bolds, you name it! 
            </p>
          </div>
        }
        textRight={
          <div>
            <h4>
              Two-Tone Glass Backsplash
            </h4>
            <p>
              Unleash your creativity by straying from the standard. Choose an opaque or gradient two-tone glass backsplash.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>
              White Glass Backsplash
            </h4>
            <p>
              Nothing speaks modern better than an all-white theme. Make your interiors, the perfect space to relax with a white glass backsplash.
            </p>
          </div>
        }
        textRight={
          <div>
            <h4>
              Grey Glass Backsplash
            </h4>
            <p>
              Crude, raw looks are always accompanied by subtle grey tones. Choose an easy-to-maintain grey for your glass backsplash.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>
              Black Glass Backsplash
            </h4>
            <p>
              If your furniture is bright and eccentric, play with contrasting looks and choose a black glass backsplash for classic tones.
            </p>
          </div>
        }
        textRight={
          <div>
            <h4>
              Blue Glass Backsplash
            </h4>
            <p>
              Create an aesthetic theme with several hues of blue glass for your backsplash. Light, sky, or dark – the choices are endless! 
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        centered
        text={
          <div>
            <h3>Have You Decided on A Glass Backsplash Yet?</h3>
            <p>
              With so many different colors and types of glass backsplash to choose from, you can find one that suits your personal style. Contact us for a consultation today! We’ll help you figure out the perfect color and design for your space. We will work with our clients every step of the way to ensure they are happy with their purchase.
            </p>
            <h4>Contact Us Today for A Free Consultation!</h4>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
          </div>
        }
      />
    </PageLayout>
  )
}

export default GlassBacksplashApplications
